.tab-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: min-content;
  height: 28px;
  background-color: rgb(221, 222, 229);
  border-radius: 10px;
  padding: 4px;
}

.tab-bar > * {
  height: 100%;
}

@media (max-width: 800px) {
  .tab-bar {
    width: 100%;
  }
}
