.course-card {
  position: relative;
  background-color: white;
  color: black;
  width: 200px;
  height: 250px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  border-radius: 15px;
  display: grid;
  place-items: center;
  margin: 10px;
  border: 3px solid white;
  overflow: hidden;

}

.course-card:hover {
  cursor: pointer;
  transform: scale(1.05);
  border: 3px solid #585858;
}

.course-card.add {
  background-color: #686868;
  color: white;
  border: none;
  font-size: 64px;
  font-weight: bold;
  cursor: pointer;
  display: grid;
  place-items: center;
}

.course-card.add:hover {
  background-color: #3f3f3f;
}


.course-card #title-wrapper {
  overflow: hidden;
  max-height: 140px;
  width: 100%;
}

.course-card #title {
  margin: 0;
  padding: 0;
  font-weight: bold;

  /* font-size: 1vw !important; */
  letter-spacing: 0.5px;
}

.course-card #image {
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.course-card #image img {
  height: 25px;
}

@media screen and (max-width: 400px) {
  .course-card {
    width: 100%;
    margin: 20px 0;
  }

  .course-card #title-wrapper {
    height: 100px;
    width: 70%;
    margin: auto;
    overflow: hidden;

  }

  .cards-wrapper > * {
    width: 100%;
  }

  .course-card #title {
    font-size: 18px;
  }
}
