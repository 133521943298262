.offline-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.offline-page .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.offline-page #title {
    font-size: 32px;
    padding: 0;
    margin: 0;
}

.offline-page #description {
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 0;
}
