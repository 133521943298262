.timer {
  width: 70px;
  transition: 0.2s;
  border: 2px solid #2c83da;
  border-radius: 7px;
  padding: 3px 5px 3px 2px;
}

.timer > p {
  margin: 0;
  padding: 0;
  color: #2c83da;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: bold;
}
