.enroll-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.enroll-page .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.enroll-page #title {
    font-size: 32px;
    padding: 0;
    margin: 0;
}

.enroll-page #description {
    font-size: 16px;
    letter-spacing: 0.5px;
    padding: 0;
}

.enroll-page #enroll-btn {
    display: grid;
    place-items: center;
    width: 80px;
    transition: 0.3s ease-in-out;
}
