.courses-page {
  background-color: rgb(244, 245, 247);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90%;
}

.courses-page #title {
  font-size: 24px;
  padding: 20px 0 20px 0;
}

.courses-page .cards-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
}

@media (max-width: 800px) {
  .courses-page .cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}
