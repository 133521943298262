#back-btn {
  width: 70px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  border-radius: 7px;
  padding: 3px 5px 3px 2px;
  border: 2px solid #e0e0e0;
  color: #a2a2a2;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#back-btn img {
  width: 20px;
  height: 20px;
}

#back-btn:hover {
  border: 2px solid black;
  color: black;
}

#back-btn > p {
  margin: 0;
  padding: 0;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
}
