.upsert-course-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  display: grid;
  place-items: center;
}

.upsert-course-popup .content-wrapper {
  display: grid !important;
  place-items: center;
}

.upsert-course-form {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
}

.upsert-course-form h1#title {
  margin: 0;
  padding: 10px;
  font-size: 24px;
  margin-bottom: 10px;
}

.upsert-course-form select {
  height: 30px;
  border-radius: 10px;
  border: 2px solid rgb(64, 138, 96);
  margin-bottom: 10px;
  padding: 5px;
}

.upsert-course-form .step {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-top: 1px solid rgb(124, 124, 124);
}

.upsert-course-form .step #status {
  width: 30px;
  height: 30px;
  display: grid;
  place-items: center;
}

.upsert-course-form .step img {
  height: 20px;
}

.upsert-course-form #close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.upsert-course-form #course-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.upsert-course-form #course-file,
.upsert-course-form #image-file {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.upsert-course-form fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
.upsert-course-form fieldset legend {
  font-size: 14px;
  margin-bottom: 10px;
}

.upsert-course-form .option {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.upsert-course-form label {
  font-size: 12px;
  margin-left: 10px;
}

.upsert-course-form #step-title {
  margin: 0;
  padding: 0;
  font-size: 18px;
  margin-bottom: 5px;
}

.upsert-course-form #close-btn:hover {
  filter: brightness(0.2);
}

.upsert-course-form #close-btn img {
  width: 25px;
  height: 25px;
}

.upsert-course-form #submit-btn {
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: rgb(64, 138, 96);
  width: 120px;
  height: 35px;
  border: 1px solid rgb(64, 138, 96);
  border-radius: 10px;
  margin-bottom: 10px;
}

.upsert-course-form #submit-btn.disabled {
  filter: grayscale(100%);
  cursor: not-allowed;
}

.upsert-course-form #submit-btn.enabled:hover {
  border: 1px solid black;
  background-color: rgb(82, 183, 126);
}

.upsert-course-form #submit-btn > p {
  display: inline-flex;
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 1px;
  color: white;
}

.upsert-course-form .error-msg {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: red;
}
