.App {
  text-align: center;
  height: 100vh;
  box-sizing: border-box;
}

.App .content-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 990px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App img.logo {
  width: 100%;
  max-width: 80px;
  height: auto;
  margin: 20px 0 0 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .App .content-wrapper {
    width: 80%;
  }
}
