.modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 999;
  display: grid;
  place-items: center;
}

.modal .content-wrapper {
  display: grid !important;
  place-items: center;
}

.modal .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
}

.modal .modal-content h1 {
  width: 80%;
  margin: 0;
  padding: 10px;
  font-size: 20px;
  margin-bottom: 10px;
}

.modal .modal-content p#disclaimer {
  margin: 0;
  font-size: 14px;
  margin-bottom: 20px;
  color: red;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.modal .buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal .buttons-wrapper .button {
  margin: 0 10px 0 10px;
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.modal .buttons-wrapper .button:hover {
  filter: brightness(1.3);
}

.modal .buttons-wrapper .button#confirm {
  background-color: rgb(230, 24, 24);
  color: white;
}

.modal .buttons-wrapper .button#cancel {
  background-color: rgb(95, 95, 95);
  color: white;
}

.modal .buttons-wrapper .button > p {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 0;
  margin: 7px 10px;
}