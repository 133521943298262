.classroom-page {
  background-color: rgb(244, 245, 247);
  min-height: 100%;
  min-width: 100%;
  display: grid;
  place-items: center;
}

.classroom-page .quiz {
  position: relative;
  background-color: white;
  width: 70%;
  margin: 50px auto;
  max-width: 800px;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.classroom-page .quiz #header {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.classroom-page .quiz #header #back-btn {
  position: absolute;
}

.classroom-page .quiz #header .timer {
  position: absolute;
  right: 0;
}

.classroom-page .quiz .submit {
  position: absolute;
  right: 0;
  width: 70px;
  transition: 0.2s;
  background-color: black;
  border: 2px solid black;
  border-radius: 6px;
  cursor: pointer;
  padding: 3px 5px 3px 5px;
}

.classroom-page .quiz .submit > p {
  margin: 0;
  padding: 0;
  color: white;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: bold;
}

.classroom-page .quiz .submit:hover {
  background-color: rgb(66, 66, 66);
}

.classroom-page .quiz #title {
  width: 100%;
  font-size: 24px;
  padding: 0 80px;
  margin: 0 0 20px 0;
}

.classroom-page .quiz #question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 250px;
}

.classroom-page .quiz #question-wrapper #image {
  display: grid;
  place-items: center;
  margin: auto;
  width: 90%;
}

.classroom-page .quiz #question-wrapper #image > img {
  max-height: 350px;
  max-width: 100%;
}

.classroom-page .quiz #question-wrapper #text {
  font-size: 18px;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 600;
  text-align: left;
}

.classroom-page .quiz #question-wrapper #text > p {
  margin: 0;
  padding: 0;
}

.classroom-page .quiz #question-wrapper #choices {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 40px 0 30px 0;
}

.classroom-page .quiz #question-wrapper #choices .choice {
  background-color: #f5f5f5;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid #f5f5f5;
}

.classroom-page .quiz #question-wrapper #choices .choice > p {
  margin: 0;
  padding: 10px 20px;
  color: black;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-align: left;
}

.classroom-page .quiz #question-wrapper #choices .choice:hover {
  /* background-color: #e0e0e0; */
  filter: brightness(0.9);
}

.classroom-page .quiz #question-wrapper #choices .choice.pending {
  animation: pending-response 2s infinite;
}

.classroom-page .quiz #question-wrapper #choices .choice.selected {
  /* border: 2px solid rgb(0, 0, 0); */
}

.classroom-page .quiz #question-wrapper #choices .choice.correct {
  background-color: rgb(220, 251, 225);
  border: 2px solid rgb(84, 211, 91);
}

.classroom-page .quiz #question-wrapper #choices .choice.correct > p {
  /* font-weight: bold; */
}

.classroom-page .quiz #question-wrapper #choices .choice.incorrect {
  background-color: rgb(253, 231, 239);
  border: 2px solid rgb(220, 52, 89);
}

.classroom-page .quiz #question-wrapper #choices .choice.incorrect > p {
  /* font-weight: bold; */
}

.classroom-page .quiz #question-wrapper #explanation {
  font-size: 14px;
  padding: 0;
  margin: 0 0 30px 0;
}

.classroom-page .quiz #question-wrapper #explanation #banner {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.classroom-page .quiz #question-wrapper #explanation p {
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: left;
}

.classroom-page .quiz #navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.classroom-page .quiz #navigation > button {
  width: 120px;
  background-color: black;
  color: white;
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  border: none;
  outline: none;
}

.classroom-page .quiz #navigation > button:hover {
  background-color: #333333;
}

.classroom-page .quiz #navigation > button.disabled {
  background-color: #939393;
  cursor: default;
}

.classroom-page .quiz #questions-progress {
  margin: 15px 0 10px 0;
  max-width: 95%;
  gap: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.classroom-page .quiz #questions-progress .question {
  display: grid;
  place-items: center;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.2s;
}

.classroom-page .quiz #questions-progress .question > p {
  color: #767676;
  font-size: 12px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
}

.classroom-page .quiz #questions-progress .question.correct {
  background-color: #13a557;
  border: 2px solid #13a557;
}

.classroom-page .quiz #questions-progress .question.correct > p {
  color: white;
}

.classroom-page .quiz #questions-progress .question.incorrect {
  background-color: #e53935;
  border: 2px solid #e53935;
}

.classroom-page .quiz #questions-progress .question.incorrect > p {
  color: white;
}

.classroom-page .quiz #questions-progress .question.current {
  border: 2px solid black;
}

.classroom-page .quiz #questions-progress .question.current > p {
  color: black;
}

.classroom-page .quiz #answers-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.classroom-page .quiz #answers-status > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
  font-size: 14px;
}

.classroom-page .quiz #answers-status > div > p {
  margin: 0;
  padding: 0;
}

.circle {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-right: 5px;
}

.circle.correct {
  background-color: #13a557;
}

.circle.incorrect {
  background-color: #e53935;
}

.circle.unanswered {
  background-color: #939393;
}

.circle.total {
  background-color: black;
}

@keyframes pending-response {
  0% {
  }
  25% {
    background-color: #ff9d00;
  }
  50% {
    background-color: #ffbe56;
  }
  100% {
    background-color: #ff9d00;
  }
}

@media (max-width: 800px) {
  .classroom-page {
    height: auto;
  }
  .classroom-page .quiz {
    width: 100%;
    height: 100%;
    margin: auto;
    box-sizing: border-box;
    border-radius: 0;
    padding: 20px;
    padding-top: 0px;
  }
  .classroom-page .quiz #question-wrapper #choices {
    grid-template-columns: 1fr;
  }

  .classroom-page .quiz #questions-progress {
    max-width: 100%;
  }

  .circle {
    margin-right: 10px;
  }
}
