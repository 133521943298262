
.error-banner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.error-banner img {
    margin-top: 50px;
    width: 100%;
    max-width: 50px;
    height: auto;
}

.error-banner p {
    font-size: 16px;
    font-weight: 500;
    margin: 20px 0;
}