.course-page {
  /* background-color: rgb(244, 245, 247); */
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 90%;
  padding-bottom: 50px;
}

.course-page #course-banner {
  margin-bottom: 20px;
  width: 80%;
  margin: 10px 0 20px 0;
  padding: 20px 0 20px 0;
  position: relative;
  border-radius: 30px;
  overflow: hidden;
}

.course-page #course-banner #logo {
  transition: 0.2s ease-in-out;
  position: absolute;
  top: 35px;
  left: 150px;
  height: 50px;
}

.course-page img#background {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left top;
}

.course-page #course-banner .content-wrapper {
  /* background-color: rgb(37, 41, 118); */
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course-page #course-banner #progress {
  width: 100%;
  bottom: 15px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.course-page #course-banner #progress #answered {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.course-page #course-banner #progress #answered .category {
  margin: 0 10px;
}

.course-page #course-banner #title {
  font-size: 56px;
  font-weight: 800;
  letter-spacing: 2px;
  padding: 5px 0 5px 0;
  margin: 0;
  color: white;
}

.course-page #course-banner #description {
  margin: 0 0 20px 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  padding: 0 20px 0 20px;
}

.course-page .cards-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding: 0 30px;
}

.course-page #admin-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

.course-page #admin-controls .control {
  margin: 0 10px 0 10px;
  width: 100px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.course-page #admin-controls .control:hover {
  filter: brightness(1.3);
}

.course-page #admin-controls .control > p {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0;
  margin: 7px 10px;
}

.course-page #admin-controls #edit {
  background-color: rgb(24, 127, 230);
  color: white;
}

.course-page #admin-controls #delete {
  background-color: rgb(230, 24, 24);
  color: white;
}

@media only screen and (max-width: 1200px) {
  .course-page #course-banner #logo {
    left: 50px;
    top: 45px;
    height: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .course-page #course-banner {
    width: 90%;
  }

  .course-page #course-banner #logo {
    display: none;
  }

  .course-page #course-banner #title {
    font-size: 40px;
  }

  .course-page .cards-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .course-page #course-banner #progress #answered {
    flex-direction: column;
    align-items: start;
  }
}
