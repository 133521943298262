
.results-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
    display: grid;
    place-items: center;
}

.results-modal .content-wrapper {
    display: grid !important;
    place-items: center;
}

.results-modal .content {
    position: relative;
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}

.results-modal .content h1#title {
    margin: 0;
    padding: 10px;
    font-size: 24px;
    margin-bottom: 0;
}

.results-modal .content #results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.results-modal .content #results > p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 10px;
}

.results-modal .content #results table {
    width: 100%;
    border-collapse: collapse;
}

.results-modal .content #results table td#key {
    padding: 5px;
    margin: 0;
    width: 50%;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: right;
}

.results-modal .content #results table td#value {
    padding: 5px;
    margin: 0;
    width: 50%;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-align: left;
}

.results-modal .content #results td.correct {
    color: #13a557;
}

.results-modal .content #results td.incorrect {
    color: #e53935;
}

.results-modal .content #results td.total {
    color: black;
}

.results-modal .content #actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.results-modal .content #actions .action {
    margin: 0 7px 0 7px;
    width: 100px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    background-color: black;
}

.results-modal .content #actions .action:hover {
    background-color: rgb(24, 127, 230);
}

.results-modal .content #actions .action > p {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.5px;
    padding: 0;
    margin: 7px 10px;
    color: white;
}

@media (max-width: 768px) {

    .results-modal .content h1#title {
        font-size: 20px;
    }
    .results-modal .content #results table td#key {
        font-size: 12px;
    }

    .results-modal .content #results table td#value {
        font-size: 12px;
    }

    .results-modal .content #results > p {
        font-size: 14px;
    }

    .results-modal .content #actions .action {
        margin: 0 3px 0 3px;
        width: 70px;
    }

    .results-modal .content #actions .action > p {
        font-size: 10px;
    }
}
