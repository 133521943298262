.quizzes-tab {
  width: 100%;
}

.quizzes-tab #banner {
  font-size: 12px;
  text-align: left;
  margin-top: 5px;
  padding: 10px 0;
}
