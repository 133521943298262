.menu-bar {
  position: absolute;
  top: 40px;
  z-index: 2;
  left: 50%;
  border-radius: 50px;
  padding: 15px;
  transform: translateX(-50%);
  height: 60px;
  box-sizing: border-box;
  width: min-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(50, 54, 142);
}

.menu-bar .page {
  width: 120px;
  height: 100%;
  border-radius: 50px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  display: grid;
  place-items: center;
  margin: 0;
}

.menu-bar .page > p {
  color: white;
  font-size: 14px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  letter-spacing: 0.3px;
}

.menu-bar .page:hover {
  background-color: rgb(103, 107, 188);
}

@media (max-width: 800px) {

  .menu-bar .page {
    width: 80px;
    margin: 0 5px;
    font-size: 12px;
  }
}
