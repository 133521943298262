.btn {
  margin-top: 5px;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: rgb(61, 65, 141);
}

.btn:hover {
  background-color: black;
}

.btn > p {
  color: white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
  margin: 0;
  padding: 5px 0px;
}
