.auth-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.auth-page .logo {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.auth-page .logo img {
  height: 50px;
}

.auth-page #title {
  font-size: 48px;
  padding: 0;
  margin: 0;
}

.auth-page #description {
  font-size: 16px;
  letter-spacing: 0.5px;
  padding: 0;
}

.auth-page #auth-btn {
  display: grid;
  place-items: center;
  width: 80px;
  transition: 0.3s ease-in-out;
}

@media screen and (max-width: 800px) {
  .auth-page #title {
    font-size: 36px;
  }

  .auth-page #description {
    font-size: 14px;
  }
}
