body {
  background-color: #f9f9f9;
}

.demo {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 30px;
  box-sizing: border-box;
}

.demo .quiz {
  position: relative;
  margin: auto;
  box-sizing: border-box;
  min-height: 400px;
  height: 100%;
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.demo .quiz #question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: auto;
  min-height: 250px;
}

.demo .quiz #question-wrapper #image {
  display: grid;
  place-items: center;
  width: 90%;
}

.demo .quiz #question-wrapper #image > img {
  max-height: 350px;
  max-width: 100%;
}

.demo .quiz #question-wrapper #text {
  font-size: 18px;
  padding: 0;
  margin: 0 0 20px 0;
  font-weight: 600;
}

.demo .quiz #question-wrapper #text > p {
  margin: 0;
  padding: 0;
  text-align: left;
}

.demo .quiz #question-wrapper #choices {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin: 40px 0 30px 0;
}

.demo .quiz #question-wrapper #choices .choice {
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  border: 2px solid #f5f5f5;
}

.demo .quiz #question-wrapper #choices .choice > p {
  margin: 0;
  padding: 10px 20px;
  color: black;
  letter-spacing: 0.5px;
  font-size: 14px;
  text-align: left;
}

.demo .quiz #question-wrapper #choices .choice:hover {
  background-color: #e0e0e0;
}

.demo .quiz #question-wrapper #choices .choice.pending {
  animation: pending-response 2s infinite;
}

.demo .quiz #question-wrapper #choices .choice.selected {
  border: 2px solid rgb(0, 0, 0);
}

.demo .quiz #question-wrapper #choices .choice.correct {
  background-color: rgb(220, 251, 225);
  border: 2px solid rgb(84, 211, 91)
}

.demo .quiz #question-wrapper #choices .choice.incorrect {
  background-color: rgb(253, 231, 239);
  border: 2px solid rgb(220, 52, 89)
}

.demo .quiz #question-wrapper #explanation {
  font-size: 14px;
  padding: 0;
  margin: 0 0 30px 0;
}

.demo .quiz #question-wrapper #explanation #banner {
  font-size: 16px;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.demo .quiz #question-wrapper #explanation p {
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: left;
}
