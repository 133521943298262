.tab {
  background-color: transparent;
  height: 100%;
  border-radius: 5px;
  display: grid;
  place-items: center;
  width: 20vw;
  max-width: 250px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.tab.active {
  background-color: white;
}

.tab.active > p {
  color: black;
}

.tab.inactive:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.tab.inactive:hover > p {
  color: black;
}

.tab > p {
  color: rgb(149, 148, 148);
  margin: 0;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.3px;
  transition: color 0.3s ease-in-out;
}

@media (max-width: 800px) {
  .tab {
    width: 26vw;
  }

  .tab > p {
    font-size: 12px;
  }
}
