div.quiz-card {
  position: relative;
  background-color: white;
  color: black;
  border-radius: 7px;
  width: 300px;
  height: 150px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 12px;
  box-sizing: border-box;
  transition: 0.2s ease-in-out;
  margin: 5px;
  display: flex;
  flex-direction: row;
}

div.quiz-card #details {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
}

div.quiz-card #controls {
  flex: 0 0 75px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: end;
}

div.quiz-card.progress {
  border: 2px solid rgb(61, 65, 141);
}

div.quiz-card:hover {
  box-shadow: rgb(149, 157, 165) 0px 8px 24px;
  transform: scale(1.02);
}

div.quiz-card #name {
  width: 100%;
  margin: 0 0 5px 0;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

div.quiz-card #description {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

div.quiz-card #image {
  display: grid;
  place-items: center;
  position: absolute;
  bottom: 10px;
  left: 12px;
}

div.quiz-card #image img {
  height: 20px;
}

/* div.quiz-card #action-button {
  background-color: white;
  width: 60px;
  height: 23px;
  text-align: center;
  border: 1px solid rgb(73, 73, 73);
  border-radius: 5px;
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
  padding: 0 5px;
  bottom: 12px;
  right: 12px;
} */

/* div.quiz-card #action-button:hover {
  filter: brightness(0.9);
  transition: 0.3s;
} */

div.quiz-card #action-button #label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

div.quiz-card #action-button #label > p {
  font-size: 11px;
  margin: 0;
  padding: 0;
  color: black;
  letter-spacing: 0.5px;

  text-overflow: ellipsis;
  white-space: nowrap;
}

#progress {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#progress #answered {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

#progress #warning {
  background-color: rgb(255, 200, 0);
  border-radius: 5px;
  padding: 2px 3px;
}

#progress .category {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#progress .category > p {
  margin: 0;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  div.quiz-card {
    position: relative;
    background-color: white;
    color: black;
    border-radius: 10px;
    width: 100%;
    max-width: 1000px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 14px;
    box-sizing: border-box;
  }
}
